<template>
  <div class="m-slider">
    <div class="swiper-container" ref="slider">
      <div class="swiper-wrapper">
        <slot/>
      </div>
    </div>
    <div class="m-slider__controls">
      <div ref="dots" class="m-slider__dots"></div>
      <div class="m-slider__nav">
        <div class="m-slider__nav-item m-slider__nav-left" ref="navLeft">
          <svg class="m-slider__svg" aria-hidden="true" height="1em" width="1em">
            <use xlink:href="/dist/img/sprite.svg#arrow-right"/>
          </svg>
        </div>
        <div class="m-slider__nav-item m-slider__nav-right" ref="navRight">
          <svg class="m-customer-slider__svg" aria-hidden="true" height="1em" width="1em">
            <use xlink:href="/dist/img/sprite.svg#arrow-right"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.min.js';

export default {
  mounted() {
    var options = {
      autoplay: {
        delay: 5000,
      },
      fadeEffect: {
        crossFade: true,
      },
      speed: 800,
      preloadImages: false,
      lazy: true,
      longSwipesRatio: 0.1,
      centeredSlides: false,
      slidesPerView: 1,
      spaceBetween: 32,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      pagination: {
        el: this.$refs.dots,
      },
      navigation: {
        nextEl: this.$refs.navRight,
        prevEl: this.$refs.navLeft,
      },
    };
    this.slider = new Swiper(this.$refs.slider, options);
  },
}
</script>
