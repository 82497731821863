<!-- FAQ item -->
<template>
  <div class="m-panel" :class="{ 'is-open': isOpen }">
    <div class="m-panel__header">
      <button @click="toggle" role="button" class="m-panel__toggle" :aria-expanded="String(isOpen)" :aria-controls="id" :aria-label="toggleLabel">
        <h3 class="m-panel__title">
          <slot name="title"></slot>
        </h3>
        <svg class="m-panel__toggle-icon" aria-hidden="true">
          <use xlink:href="/dist/img/sprite.svg#arrow-right" />
        </svg>
      </button>
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave">
      <div ref="panelContent" class="m-panel__content" v-show="isOpen" :aria-expanded="String(isOpen)" :aria-hidden="String(!isOpen)">
        <div ref="panelInner" class="m-panel__inner">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      toggleLabel: {
        type: String,
        default: 'Klik om te openen',
      },
    },
    data() {
      return {
        isOpen: false,
        id: 'panel_' + this._uid,
      }
    },
    methods: {
      toggle() {
        this.isOpen = !this.isOpen;
        this.$emit('panel-change', { panel: this, isOpen: this.isOpen });
      },
      beforeEnter(el) {
        el.style.height = 0;
      },
      enter(el, done) {
        el.style.height = this.getInnerHeight();
        el.addEventListener('transitionend oTransitionEnd webkitTransitionEnd', (e) => {
          done();
        });
      },
      afterEnter (el) {
        el.style.height = 'auto';
      },
      beforeLeave(el) {
        el.style.height = this.getInnerHeight();
      },
      leave (el, done) {
        el.style.height = 0;
        el.addEventListener('transitionend oTransitionEnd webkitTransitionEnd', (e) => {
          done();
        });
      },
      getInnerHeight() {
        return this.$refs.panelInner.scrollHeight + 'px';
      },
    },
  }
</script>
