var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isStored
    ? _c(
        "div",
        {
          staticClass: "m-notification",
          class: { "is-active": !_vm.isStored }
        },
        [
          _c("div", { staticClass: "m-notification__inner" }, [
            _c("div", { staticClass: "m-notification__content" }, [
              _c(
                "svg",
                {
                  staticClass: "m-notification___svg-icon",
                  attrs: { "aria-hidden": "true", width: "1em", height: "1em" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "/dist/img/sprite.svg#explain" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "m-notification__raw",
                domProps: { innerHTML: _vm._s(_vm.title) }
              })
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "m-notification__button",
                on: { click: _vm.closeNotification }
              },
              [
                _vm._v("\n      Sluit\n      "),
                _c(
                  "svg",
                  {
                    staticClass: "m-notification___toggle-icon",
                    attrs: {
                      "aria-hidden": "true",
                      width: "1em",
                      height: "1em"
                    }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/dist/img/sprite.svg#times" }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }