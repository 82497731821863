var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t-team__inner o-container__inner",
      on: { click: _vm.toggleMember }
    },
    [
      _c("div", { staticClass: "t-team__content-wrapper" }, [
        _c("div", { staticClass: "t-team__content-head" }, [_vm._t("head")], 2),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "t-team__image-wrapper",
            class: { "is-active": this.show }
          },
          [
            _c(
              "figure",
              { staticClass: "t-team__image a-image" },
              [_vm._t("image")],
              2
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show"
                }
              ],
              ref: "panelContent",
              staticClass: "t-team__content m-panel__content",
              attrs: {
                "aria-expanded": String(_vm.show),
                "aria-hidden": String(!_vm.show)
              }
            },
            [_c("div", { ref: "panelInner" }, [_vm._t("text")], 2)]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "t-team__toggle", class: { "is-active": this.show } },
        [
          _vm._v("\n      " + _vm._s(_vm.buttonText) + "\n      "),
          _c(
            "svg",
            {
              staticClass: "t-team__toggle-icon",
              attrs: { "aria-hidden": "true", width: "1em", height: "1em" }
            },
            [
              _c("use", {
                attrs: { "xlink:href": "/dist/img/sprite.svg#arrow-right" }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }