<template>
	<div class="t-team__inner o-container__inner" @click="toggleMember">
    <div class="t-team__content-wrapper">
      <div class="t-team__content-head">
        <slot name="head"></slot>
      </div>

      <div class="t-team__image-wrapper" :class="{ 'is-active': this.show }">
        <figure class="t-team__image a-image">
          <slot name="image"></slot>
        </figure>
      </div>
    </div>

    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave">
      <div ref="panelContent" class="t-team__content m-panel__content" v-show="show" :aria-expanded="String(show)" :aria-hidden="String(!show)">
        <div ref="panelInner">
          <slot name="text"></slot>
        </div>
      </div>
    </transition>

    <span class="t-team__toggle" :class="{ 'is-active': this.show }">
      {{ buttonText }}
      <svg class="t-team__toggle-icon" aria-hidden="true" width="1em" height="1em">
        <use xlink:href="/dist/img/sprite.svg#arrow-right" />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      buttonText: 'Toon meer',
    }
  },
  methods: {
    toggleMember() {
      this.show = !this.show;
      this.show ? this.buttonText = 'Verberg' : this.buttonText = 'Toon meer';
    },
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el, done) {
      el.style.height = this.getInnerHeight();
      el.addEventListener('transitionend oTransitionEnd webkitTransitionEnd', (e) => {
        done();
      });
    },
    afterEnter (el) {
      el.style.height = 'auto';
    },
    beforeLeave(el) {
      el.style.height = this.getInnerHeight();
    },
    leave (el, done) {
      el.style.height = 0;
      el.addEventListener('transitionend oTransitionEnd webkitTransitionEnd', (e) => {
        done();
      });
    },
    getInnerHeight() {
      return this.$refs.panelInner.scrollHeight + 'px';
    },
  }
}
</script>