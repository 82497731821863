var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-slider" }, [
    _c("div", { ref: "slider", staticClass: "swiper-container" }, [
      _c("div", { staticClass: "swiper-wrapper" }, [_vm._t("default")], 2)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "m-slider__controls" }, [
      _c("div", { ref: "dots", staticClass: "m-slider__dots" }),
      _vm._v(" "),
      _c("div", { staticClass: "m-slider__nav" }, [
        _c(
          "div",
          {
            ref: "navLeft",
            staticClass: "m-slider__nav-item m-slider__nav-left"
          },
          [
            _c(
              "svg",
              {
                staticClass: "m-slider__svg",
                attrs: { "aria-hidden": "true", height: "1em", width: "1em" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "/dist/img/sprite.svg#arrow-right" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "navRight",
            staticClass: "m-slider__nav-item m-slider__nav-right"
          },
          [
            _c(
              "svg",
              {
                staticClass: "m-customer-slider__svg",
                attrs: { "aria-hidden": "true", height: "1em", width: "1em" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "/dist/img/sprite.svg#arrow-right" }
                })
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }