<!-- FAQ component -->
<template>
  <div class="m-panel-group">
    <div class="m-panel-group__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leaveOpen: {
      type: Boolean,
      default: false,
    },
  },
  mounted () {
    var _self = this;
    if(!_self.leaveOpen) {
      _self.$children.map(panel => {
        panel.$on('panel-change', (e) => {
          _self.$children.map((child) => {
            if(child != panel) {
              child.isOpen = false;
            }
          });
        });
      });
    }
  },
}
</script>
