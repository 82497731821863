var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-panel", class: { "is-open": _vm.isOpen } },
    [
      _c("div", { staticClass: "m-panel__header" }, [
        _c(
          "button",
          {
            staticClass: "m-panel__toggle",
            attrs: {
              role: "button",
              "aria-expanded": String(_vm.isOpen),
              "aria-controls": _vm.id,
              "aria-label": _vm.toggleLabel
            },
            on: { click: _vm.toggle }
          },
          [
            _c("h3", { staticClass: "m-panel__title" }, [_vm._t("title")], 2),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "m-panel__toggle-icon",
                attrs: { "aria-hidden": "true" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "/dist/img/sprite.svg#arrow-right" }
                })
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen"
                }
              ],
              ref: "panelContent",
              staticClass: "m-panel__content",
              attrs: {
                "aria-expanded": String(_vm.isOpen),
                "aria-hidden": String(!_vm.isOpen)
              }
            },
            [
              _c(
                "div",
                { ref: "panelInner", staticClass: "m-panel__inner" },
                [_vm._t("default")],
                2
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }