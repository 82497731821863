<template>
  <div v-if="!isStored" class="m-notification" :class="{ 'is-active': !isStored }">
    <div class="m-notification__inner">
      <div class="m-notification__content">
        <svg class="m-notification___svg-icon" aria-hidden="true" width="1em" height="1em">
          <use xlink:href="/dist/img/sprite.svg#explain" />
        </svg>
        <div class="m-notification__raw" v-html="title"></div>
      </div>
      <button @click="closeNotification" class="m-notification__button">
        Sluit
        <svg class="m-notification___toggle-icon" aria-hidden="true" width="1em" height="1em">
          <use xlink:href="/dist/img/sprite.svg#times" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    }
  },
  data() {
    return {
      isStored: false,
    }
  },
  mounted() {
    this.isStored = sessionStorage.getItem('isStored');
  },
  methods: {
    closeNotification() {
      sessionStorage.setItem('isStored', true);
      this.isStored = true;
    },
  },
}
</script>