/* eslint-disable */
import 'what-input';
import 'core-js';
import LazyLoad from 'vanilla-lazyload';
import FontFaceObserver from 'fontfaceobserver';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import inView from 'in-view';

import Vue from 'vue';
import BasePanel from '../components/BasePanel';
import BasePanelGroup from '../components/BasePanelGroup';
import BaseSlider from '../components/BaseSlider';
import BaseSliderItem from '../components/BaseSliderItem';
import BaseAlert from '../components/BaseAlert';
import BaseToggleMember from '../components/BaseToggleMember';
import { Tabs, Tab } from 'vue-tabs-component';

const html = document.documentElement;
if (sessionStorage.fontsLoaded) {
  html.classList.add('fonts-loaded');
} else {
  const font = new FontFaceObserver('Inter');
  html.classList.add('fonts-loading');
  Promise.all([font.load()]).then(() => {
    sessionStorage.fontsLoaded = true;
    html.classList.remove('fonts-loading');
    html.classList.add('fonts-loaded');
  }).catch(() => {
    sessionStorage.fontsLoaded = false;
    html.classList.remove('fonts-loading');
    html.classList.add('fonts-failed');
  });
}

new Vue({
  el: '#app',
  components: {
    BasePanel,
    BasePanelGroup,
    BaseSlider,
    BaseSliderItem,
    BaseAlert,
    BaseToggleMember,
    Tabs,
    Tab,
  },
  data() {
    return {
      mobileNavActive: false,
      lazyLoad: null,
      filterActive: false,
    };
  },
  mounted() {
    objectFitImages();
    svg4everybody();

    this.lazyLoad = new LazyLoad({
      class_loading: 'loading',
      class_loaded: 'loaded',
      elements_selector: '.lazy',
    });

    inView('.animated-component').on('enter', el => {
      el.classList.add('in-view')
    });
    inView('.animated-children').once('enter', el => {
      el.classList.add('in-view')
    });
  },
  methods: {
    toggleNav() {
      const body = document.querySelector('body');
      this.mobileNavActive = !this.mobileNavActive;
      this.mobileNavActive ? body.classList.add('has-mobile-overlay') : body.classList.remove('has-mobile-overlay');
    },
    toggleFilter() {
      this.filterActive = !this.filterActive;
    },
  },
});
